export const useAudioPlayerStore = defineStore("audio-player", () => {
  const isOpen = ref(false);
  const source = ref(
    "",
  );
  const title = ref(
    "SharkTalk, SharkTalk #35 - Zuzana Paulovics (Start it @ČSOB): Přes 80 % startupů z našeho akcelerátoru uspěje",
  );

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
  };

  return {
    isOpen,
    open,
    close,
    source,
    title,
  };
});
